import React from "react";
import { BrowserRouter } from "react-router-dom";
import JobDescriptionPage from "./views/JobDescriptionPage";
import ApplyJobPage from "./views/ApplyJobPage/ApplyJobPage";
import CompanyDescriptionPage from "./views/CompanyDescriptionPage";
import { TelegramProvider } from "./utils/Telegram.Provider";
import { ThemeProviderWrapper } from "./utils/Theme.Provider";
import { useSearchParams } from "react-router-dom";
import { isValidObjectId } from "./utils/isValidId";
import HelpPage from "./views/HelpPage";
import "./app.css";

const ContentSwitcher = () => {
  const [searchParams] = useSearchParams();
  const tgInitParam = searchParams.get("tgWebAppStartParam");

  const [activeView, setActiveView] = React.useState<string>("welcome");
  const [entityId, setEntityId] = React.useState<string>("");

  const navigateToApply = () => {
    setActiveView("application");
  };

  React.useEffect(() => {
    if (!tgInitParam) {
      setActiveView("welcome");
    } else {
      const splitedParam = tgInitParam.split("_");
      if (splitedParam.length > 2) {
        setActiveView("welcome");
      }
      const [prefix, id] = splitedParam;
      if (id && !isValidObjectId(id)) {
        setActiveView("welcome");
      }
      setEntityId(id);

      switch (prefix) {
        case "CMP":
          setActiveView("company");
          break;
        case "OPN":
          setActiveView("opening");
          break;
        case "APP":
          setActiveView("application");
          break;
        case "HELP":
          setActiveView("help");
          break;
        default:
          setActiveView("welcome");
          break;
      }
    }
  }, [tgInitParam]);

  switch (activeView) {
    case "company":
      return <CompanyDescriptionPage companyId={entityId} />;
    case "opening":
      return (
        <JobDescriptionPage
          jobId={entityId}
          navigateToApply={navigateToApply}
        />
      );
    case "application":
      return <ApplyJobPage jobId={entityId} />;
    case "help":
      return <HelpPage />;
    default:
      return <></>;
  }
};

const App = () => {
  return (
    <TelegramProvider>
      <BrowserRouter>
        <ThemeProviderWrapper>
          <div className="app-container">
            <ContentSwitcher />
          </div>
        </ThemeProviderWrapper>
      </BrowserRouter>
    </TelegramProvider>
  );
};

export default App;
