import { Company } from "./company.fetcher";

export interface JobOpening {
  id: string;
  companyId: string;
  title: string;
  description: string;
  company: Company;
}

interface FetchJobArgs {
  jobId: string;
  onSuccess: (data: JobOpening) => void;
  onError: (err: Error) => void;
}

export const fetchOpening = ({ jobId, onSuccess, onError }: FetchJobArgs) => {
  fetch(`${process.env.REACT_APP_SERVER_URL}/api/openings/${jobId}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      return response.json();
    })
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onError(error);
    });
};
