import React from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

// Define the custom styled Switch
const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#B400FF", // Thumb color when enabled
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(180, 0, 255, 0.2)" // Dark mode hover
          : "rgba(180, 0, 255, 0.08)", // Light mode hover
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#B400FF", // Track color when enabled
  },
  "& .MuiSwitch-switchBase": {
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.1)" // Dark mode hover when not checked
          : "rgba(0, 0, 0, 0.1)", // Light mode hover when not checked
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.mode === "dark" ? "#666" : "#ccc", // Track color when disabled
  },
}));

// Custom Switch component that takes checked, onChange, and other props
const ThemedSwitch = (props: SwitchProps) => {
  return <CustomSwitch {...props} />;
};

export default ThemedSwitch;
