import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useApplicationForm } from "../hooks/form.hook";
import { useTelegram } from "../../../utils/Telegram.Provider";
import { useFileInput } from "../hooks/fileInput.hook";
import {
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Button,
  FormControlLabel,
} from "@mui/material";
import { JobOpening } from "../../../api/opening.fetcher";
import ThemedSwitch from "../../../components/Switch/Switch";

interface ApplicationFormState {
  firstName: string;
  lastName: string;
  email: string;
  xProfile: string;
  portfolio: string;
  comment: string;
}

const FileUploadField = ({
  file,
  handleFileChange,
  clearFile,
}: {
  file: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFile: () => void;
}) => (
  <div>
    <input
      id="file-input"
      type="file"
      accept="application/pdf"
      style={{ display: "none" }}
      onChange={handleFileChange}
    />
    <TextField
      variant="outlined"
      InputProps={{
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            {file ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  alignItems: "center",
                }}
              >
                <Typography>{file.name}</Typography>
                <IconButton onClick={clearFile}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </div>
            ) : (
              <Button
                size="small"
                onClick={() => document.getElementById("file-input")!.click()}
              >
                Attach resume *
              </Button>
            )}
          </InputAdornment>
        ),
      }}
      helperText="Max size 4MB - PDF Only"
      error={
        !!file &&
        (file.size > 4 * 1024 * 1024 || file.type !== "application/pdf")
      }
      size="small"
      fullWidth
      required
    />
  </div>
);

const FormFields = ({
  formData,
  handleChange,
  file,
  handleFileChange,
  clearFile,
}: {
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  file: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFile: () => void;
}) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
      <TextField
        name="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        helperText="Max 25 characters"
        error={formData.firstName.length > 25}
        size="small"
        fullWidth
        required
      />
      <TextField
        name="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        helperText="Max 25 characters"
        error={formData.lastName.length > 25}
        size="small"
        fullWidth
        required
      />
    </div>
    <TextField
      name="email"
      label="Email"
      type="email"
      value={formData.email}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      helperText="Max 50 characters"
      error={formData.email.length > 50}
      size="small"
      fullWidth
      required
    />
    <FileUploadField
      file={file}
      handleFileChange={handleFileChange}
      clearFile={clearFile}
    />
    <TextField
      name="xProfile"
      label="X Profile"
      type="url"
      value={formData.xProfile}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      helperText="Max 50 characters"
      error={formData.xProfile.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="portfolio"
      label="Github, Linkedin or Portfolio"
      type="url"
      value={formData.portfolio}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      helperText="Max 50 characters"
      error={formData.portfolio.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="comment"
      label="Comment"
      multiline
      rows={4}
      value={formData.comment}
      onChange={handleChange}
      inputProps={{ maxLength: 1000 }}
      helperText="Max 1000 characters"
      error={formData.comment.length > 1000}
      fullWidth
    />
  </div>
);

export const ApplicationForm = ({
  jobOpening,
  onSuccess,
  onError,
}: {
  jobOpening: JobOpening;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { formData, handleChange } = useApplicationForm<ApplicationFormState>({
    firstName: "",
    lastName: "",
    email: "",
    xProfile: "",
    portfolio: "",
    comment: "",
  });

  const { file, handleFileChange, clearFile } = useFileInput();
  const [shareTelegramContact, setShareTelegramContact] =
    React.useState<boolean>(true);
  const telegram = useTelegram();

  const handleSubmit = React.useCallback(() => {
    const payload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) payload.append(key, value);
    });

    payload.append("openingId", jobOpening.id);
    payload.append("companyId", jobOpening.companyId);
    if (file) payload.append("resume", file);
    if (shareTelegramContact && telegram) {
      const { id, username } = telegram.initDataUnsafe.user;
      payload.append("telegramId", String(id));
      payload.append("telegramUsername", username || "");
    }

    telegram?.MainButton.disable();

    fetch(`${process.env.REACT_APP_SERVER_URL}/api/applications`, {
      method: "POST",
      body: payload,
    })
      .then((response) => {
        response.status < 400 ? handleSuccess() : handleError();
      })
      .catch(handleError);

    function handleSuccess() {
      telegram?.MainButton.hideProgress();
      onSuccess();
    }

    function handleError() {
      telegram?.MainButton.hideProgress();
      onError();
    }
  }, [
    formData,
    jobOpening,
    file,
    shareTelegramContact,
    telegram,
    onSuccess,
    onError,
  ]);

  const isFormValid = React.useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      !!file &&
      file.type === "application/pdf" &&
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      emailRegex.test(formData.email)
    );
  }, [file, formData]);

  React.useEffect(() => {
    if (telegram) {
      telegram.MainButton.onClick(handleSubmit);
      telegram.MainButton.setText("Submit application");
      telegram.MainButton.show();

      return () => telegram.MainButton.offClick(handleSubmit);
    }
  }, [handleSubmit, telegram]);

  React.useEffect(() => {
    if (telegram) {
      isFormValid
        ? telegram.MainButton.enable()
        : telegram.MainButton.disable();
    }
  }, [telegram, isFormValid]);

  return (
    <div style={{ padding: "8px 2%" }}>
      <Typography variant="h4">{jobOpening.title}</Typography>
      <Typography variant="h5" paddingBottom="16px">
        At {jobOpening.company?.name}
      </Typography>
      <FormFields
        formData={formData}
        handleChange={handleChange}
        file={file}
        handleFileChange={handleFileChange}
        clearFile={clearFile}
      />
      <FormControlLabel
        control={
          <ThemedSwitch
            checked={shareTelegramContact}
            onChange={(e) => setShareTelegramContact(e.target.checked)}
          />
        }
        label="Share my Telegram contact"
      />
    </div>
  );
};
