import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(to bottom, #ffffff, rgba(132, 40, 215, 0.8))",
          minHeight: "100vh",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#ffffff",
            "& fieldset": {
              borderColor: "#6A27A7",
            },
            "&:hover fieldset": {
              borderColor: "#B400FF",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#6A27A7",
            },
          },
          "& .MuiInputLabel-root": {
            "&.Mui-focused": {
              color: "#6A27A7",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#6A27A7",
          "&:hover": {
            backgroundColor: "#B400FF", // Hover background color
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(to bottom, #121212, rgba(93, 56, 144, 1))",
          minHeight: "100vh",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#212121",
            "& fieldset": {
              borderColor: "#6A27A7",
            },
            "&:hover fieldset": {
              borderColor: "#B400FF",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#6A27A7",
            },
          },
          "& .MuiInputLabel-root": {
            "&.Mui-focused": {
              color: "#6A27A7",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#6A27A7",
          "&:hover": {
            backgroundColor: "#B400FF", // Hover background color
          },
        },
      },
    },
  },
});

export const getTheme = (mode: "light" | "dark") =>
  mode === "light" ? lightTheme : darkTheme;
