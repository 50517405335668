import React, { useState, useEffect } from "react";
import ApplicationSuccess from "../ApplicationSuccess";
import { JobOpening, fetchOpening } from "../../api/opening.fetcher";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../../components/NotFound/NotFound";
import { ApplicationForm } from "./components/ApplicationForm";

const ApplyJobPage = ({ jobId }: { jobId: string }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [opening, setOpening] = useState<JobOpening>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchOpening({
      jobId,
      onSuccess: (data) => {
        setOpening(data);
        setLoading(false);
      },
      onError: () => setLoading(false),
    });
  }, [jobId]);

  if (loading) return <LoadingIndicator />;
  if (!opening) return <NotFound />;
  if (isSuccess) return <ApplicationSuccess />;

  return (
    <ApplicationForm
      jobOpening={opening}
      onSuccess={() => setIsSuccess(true)}
      onError={() => {}}
    />
  );
};

export default ApplyJobPage;
