interface FetchCompanyArgs {
  companyId: string;
  onSuccess: (data: Company) => void;
  onError: (err: Error) => void;
}

export interface Company {
  id: string;
  name: string;
  bannerPath: string;
  description: string;
  summary: string;
}

export const fetchCompany = ({
  companyId,
  onSuccess,
  onError,
}: FetchCompanyArgs) => {
  fetch(`${process.env.REACT_APP_SERVER_URL}/api/companies/${companyId}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      return response.json();
    })
    .then((data: Company) => {
      onSuccess(data);
    })
    .catch((error: Error) => onError(error));
};
