import { useEffect } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { useTelegram } from "../utils/Telegram.Provider";
import CustomBox from "../components/Box/Box";

const HelpPage = () => {
  const webApp = useTelegram();

  useEffect(() => {
    if (!webApp) {
      return;
    }
    webApp.MainButton.onClick(webApp.close);
    webApp.MainButton.setText("Close");
    webApp.MainButton.show();
  }, [webApp]);

  return (
    <CustomBox sx={{ height: "auto", marginX: "8px", marginY: "4px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "start",
          paddingTop: "4px",
          paddingLeft: "2%",
          paddingRight: "2%",
          margin: 2,
        }}
      >
        <Avatar
          variant="square"
          src={"/assets/images/logo.png"}
          sx={{ width: 100, height: 100 }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "2%",
          paddingRight: "2%",
          margin: 2,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            marginBottom: 2,
            fontSize: "17px",
          }}
        >
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            Unicast.ai
          </Box>{" "}
          is a company based in Europe 🇪🇺
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            marginBottom: 2,
            fontSize: "17px",
          }}
        >
          We've built this recruiting bot to help people find great
          opportunities in the Web3 space!
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            marginBottom: 2,
            fontSize: "17px",
          }}
        >
          You can browse & apply to jobs{" "}
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            directly on Telegram!
          </Box>{" "}
          Share jobs & refer friends to{" "}
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            earn rewards!
          </Box>{" "}
          (coming soon)
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            marginBottom: 2,
            fontSize: "17px",
          }}
        >
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            Join
          </Box>{" "}
          our community channel and follow us on X 🤝
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "17px",
          }}
        >
          <Link href="https://x.com/unicastai" underline="hover">
            x.com/unicast
          </Link>
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            marginBottom: 2,
            fontSize: "17px",
          }}
        >
          <Link href="https://t.me/unicastaicommunity" underline="hover">
            t.me/unicastaicommunity
          </Link>
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "17px",
          }}
        >
          To reach out to us: team@unicast.ai
        </Typography>
      </Box>
    </CustomBox>
  );
};

export default HelpPage;
