import React from "react";
import { useTelegram } from "../utils/Telegram.Provider";
import AnimatedSuccess from "../components/AnimatedSuccess";
import { Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import CustomBox from "../components/Box/Box";

function ApplicationSuccess() {
  const webApp = useTelegram();

  React.useEffect(() => {
    if (!webApp) {
      return;
    }
    webApp.MainButton.onClick(webApp.close);
    webApp.MainButton.setText("Done");
  }, [webApp]);

  return (
    <CustomBox
      sx={{
        height: "auto",
        marginX: "8px",
        marginY: "4px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "start",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <AnimatedSuccess />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: "2%",
          paddingRight: "2%",
          margin: 2,
        }}
      >
        <Typography
          variant="h6"
          textAlign="center"
          sx={{
            marginBottom: 2,
            fontWeight: "bold",
          }}
        >
          Your application was submitted successfully! 🎉
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            marginBottom: 2,
            fontSize: "17px",
          }}
        >
          The company will reach out directly to you if they proceed with your
          application.
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            marginBottom: 2,
            fontSize: "17px",
          }}
        >
          In the meantime, do follow our X and join our Web3 Jobboard! 🤝
        </Typography>
        <Link
          href="http://x.com/unicastai"
          underline="hover"
          sx={{
            marginBottom: 1,
            fontSize: "17px",
          }}
        >
          x.com/unicast
        </Link>
        <Link
          href="http://x.com/unicastai"
          underline="hover"
          sx={{
            marginBottom: 1,
            fontSize: "17px",
          }}
        >
          t.me/unicastjobs
        </Link>
      </Box>
    </CustomBox>
  );
}

export default ApplicationSuccess;
