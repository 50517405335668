import React, { createContext, useState, useContext, ReactNode } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useTelegram } from "./Telegram.Provider";
import { getTheme } from "./theme";

interface ThemeContextProps {
  toggleTheme: () => void;
  mode: "light" | "dark";
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProviderWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const webApp = useTelegram();
  const [mode, setMode] = useState<"light" | "dark">(
    webApp?.colorScheme ?? "light"
  );
  const [themeReady, setThemeReady] = useState(false);

  React.useEffect(() => {
    if (webApp?.colorScheme) {
      setMode(webApp?.colorScheme);
      setThemeReady(true);
    }
  }, [webApp?.colorScheme]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const theme = React.useMemo(() => getTheme(mode), [mode]);

  if (!themeReady) {
    return null;
  }

  return (
    <ThemeContext.Provider value={{ toggleTheme, mode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error(
      "useThemeContext must be used within a ThemeProviderWrapper"
    );
  }
  return context;
};
