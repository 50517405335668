import React from "react";
import {
  Typography,
  Divider,
  Alert,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import Markdown from "react-markdown";
import { makeStyles } from "@mui/styles";
import { useTelegram } from "../utils/Telegram.Provider";
import { JobOpening, fetchOpening } from "../api/opening.fetcher";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../components/NotFound/NotFound";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  content: {
    paddingBottom: "8px",
    paddingTop: "8px",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
});

const JobDescriptionPage = ({
  jobId,
  navigateToApply,
}: {
  jobId: string;
  navigateToApply: () => void;
}) => {
  const classes = useStyles();
  const [jobOpening, setJobOpening] = React.useState<JobOpening | null>(null);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const webApp = useTelegram();

  const onSuccess = React.useCallback(
    (data: JobOpening) => {
      setJobOpening(data);
      setLoading(false);
      webApp?.MainButton.onClick(navigateToApply);
      webApp?.MainButton.setText("Apply now");
      webApp?.MainButton.show();
    },
    [navigateToApply, webApp]
  );

  const onError = () => {
    setError("Failed to load job description.");
    setLoading(false);
  };

  React.useEffect(() => {
    fetchOpening({ jobId, onSuccess, onError });
  }, [jobId, onSuccess]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!jobOpening && !loading) {
    return <NotFound />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Typography
            className={classes.title}
            sx={{ fontWeight: "bold" }}
            variant="h5"
          >
            {jobOpening?.company?.name}
          </Typography>
        </CardContent>
        <CardMedia
          sx={{ height: 140 }}
          image={jobOpening?.company?.bannerPath}
          title={jobOpening?.company?.name}
        />
        <CardContent>
          <Markdown>{jobOpening!.company.summary}</Markdown>
          <Divider sx={{ margin: 2 }} />
          <Typography className={classes.title} variant="h5">
            {jobOpening!.title}
          </Typography>
          <div className={classes.content}>
            <Markdown>{jobOpening!.description}</Markdown>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default JobDescriptionPage;
